/* istanbul ignore file */
import { createSlice } from "@reduxjs/toolkit";
import { resetAll } from "./resetSlice";
import Cookies from 'js-cookie';

const initialState = {
	user: {
		userName: "",
		email: "",
		countryOfResidence: "",
		locale: "",
		authToken: (Cookies?.get("token") ? Cookies?.get("token") : ""),
		platform: null,
		currentStep: null,
		accountRejectionReason: null,
		hrinTrigger: null,
		triggerLogout: false,
		successPage: false,
		applicationStatus: null,
		databaseOfficeId: null,
		fxowsAccountNumber: null
	},
};

const userInfoSlice = createSlice({
	name: "userInfo",
	initialState,
	reducers: {
		updateUserInfo: (state, action) => {
			const payload = action.payload;
			state.user = { ...state.user, ...payload };
		},
		updateServerDetails: (state, action) => {
			const payload = action.payload;
			state.user.databaseOfficeId = payload?.databaseOfficeId;
			state.user.fxowsAccountNumber = payload?.fxowsAccountNumber;
			state.user.userName = payload?.userName;
			state.user.platform = payload?.platform;
		}
	},
	extraReducers: (builder) => {
		builder.addCase(resetAll, () => {
		  return userInfoSlice.getInitialState;
		});
	  },
});

export const { updateUserInfo, updateServerDetails } = userInfoSlice.actions;
export default userInfoSlice.reducer;
